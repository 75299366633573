<template>
  <!--<div id='app'>-->
  <div class="load-modal" v-if="showLoadModal">
      <svg width="320" height="320" viewBox="-160 -160 320 320">
        <circle fill="#426ff5" stroke="#426ff5" cx="0" cy="0" r="45"/>
          <g>
            <animateTransform attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from="0"
                  to="360"
                  dur="3.33s"
                  repeatCount="indefinite"/>            
            <g transform="translate(100, 0)">
            <g transform="translate(-124, -103), rotate(-20), scale(.8)">
              <path style="fill:none;stroke:#000077;stroke-width:1.15174;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m 156.50928,100.70449 -15.8775,9.08892 -12.82828,7.34351 H 40.333693 v -16.43243 z" />
              <rect
                style="fill:none;stroke:#000077;stroke-width:2.28896;stroke-opacity:1"
                width="1.1942058"
                height="60.670181"
                x="-89.485573"
                y="36.98373"
                transform="scale(-1,1)" />
              <path
                style="fill:none;stroke:#000077;stroke-width:0.965;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                d="M 91.923613,36.516786 V 98.315892 H 156.55756 C 129.56958,81.949893 105.4134,63.381389 91.923613,36.516786 Z"
                sodipodi:nodetypes="cccc" />
              <path
                style="fill:none;stroke:#000077;stroke-width:0.949758;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                d="M 85.860933,36.436502 V 98.323513 H 41.135573 C 66.836853,82.045178 80.141953,60.76985 85.860933,36.436502 Z"
                id="path871"
                sodipodi:nodetypes="cccc" />
              </g>
                <animateTransform attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          from="0"
                          to="360"
                          dur="100s"
                          repeatCount="indefinite"/>          
              </g>
          </g>
      </svg>
    </div>  
  <div>
    <main>

      <div class="search-box">
        <input type="text" 
        name="" 
        id="search-bar" 
        class="search-bar" 
        placeholder="Search for a vessel name"
        v-model="query"
        @keydown="fetchBoats"
        
        
        />
        <div class="searchresults">
          <ul class="dropdown-menu" style="width:100%" id="dropdwn">
                <li v-for="value in namesdata" v-bind:key="(value.VesselName, value.MMSI)" @click="setQuery" class="vesselList" :data-mmsi="value.MMSI">
                    {{ value.VesselName }}   
                </li>
          </ul>
        </div>
      </div>

      <div class="wrap">
        <div class="location-box">
          <div class="location"></div>
          <div class="date">Date:</div>
        </div>

        <div class="detail-box">
          <div class="time-box">Time:</div>
          <div class="namev">Vessel Name:</div>
        </div>

        <div class="button-wrapper">
          <button @click="getBounds" class="menu-button">
            <div class="find"></div>
            <p>Find local vessels</p>
          </button>
          <button @click="stopnClear" class="menu-button">
            <div class="stop"></div>
            <p>Stop and clear all</p>
          </button>
        </div>
        
      </div>

    </main>
    <Modal v-if="showModal" :ldata="localdata" @close="showModal = false" @newQuery="newQuery" />
    
  </div>
  <!--
  <button class="floatingTop" @click="window.scrollTo(0,0)">
    ^Back to Top^
  </button>
  -->
  
</template>

<script>
import L from "leaflet";
import Modal from './components/modal.vue';
export default {
  name: 'app',
  components: {
    Modal
  },
  data () {
    return {
      map: null,
      url_base: 'https://atsd.ryandewson.com/vesseldata/',
      names_url: 'https://atsd.ryandewson.com/names/',
      local_url: 'https://atsd.ryandewson.com/localvessels/',
      vesseldata: {},
      namesdata: [],
      localdata: {},
      query: "",
      showModal: false,
      showLoadModal: false
    }
  },
  mounted() {
    this.map = L.map("map", {minZoom: 3}).setView([39.82754, -75.35942], 10);
    L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    var southWest = L.latLng(-89.98155760646617, -180),
    northEast = L.latLng(89.99346179538875, 180);
    var bounds = L.latLngBounds(southWest, northEast);

    this.map.setMaxBounds(bounds);
    this.map.on('drag', function() {
        this.map.panInsideBounds(bounds, { animate: false });
    });
  },
  methods: {
    fetchBoats (e) {
      if (e.key == "Enter") {
        this.vesseldata = {};
        console.log(2);
        console.log(this.vesseldata);
        fetch(`${this.url_base}${this.query.toUpperCase()}`)
          .then(res => {
            return res.json();
          }).then(this.setResults);
      }
    },
    setResults (results) {
      this.showLoadModal = false;
      this.vesseldata = {};
      this.vesseldata = results;
      console.log(this.vesseldata);
      this.setSearchClose();
    },
    setNames (names) {
      this.namesdata = names;
    },
    setLocal (results) {
      this.localdata = {};
      this.localdata = results;
      console.log(this.localdata);
      this.setSearchClose();
      this.showLoadModal = false;
    },
    setSearchDisplay () {
      console.log(this.query.length);
      var dropd = document.getElementsByClassName('dropdown-menu');
      if (this.query.length == 0) {
        if (dropd[0].classList.contains('dropdown_hidden') == false) {
        dropd[0].classList.add('dropdown_hidden');
        }
      } else if (dropd[0].classList.contains('dropdown_hidden')) {
        dropd[0].classList.remove('dropdown_hidden');
      }
    },
    setSearchClose () {
      var dropd = document.getElementsByClassName('dropdown-menu');
      if (dropd[0].classList.contains('dropdown_hidden') == false) {
        dropd[0].classList.add('dropdown_hidden');
      }
    },
    setQuery (e) {
      var vesid = e.target.dataset.mmsi;
      this.showLoadModal = true;
      this.query = e.target.innerHTML;
      fetch(`${this.url_base}${this.query.toUpperCase()}/${vesid}`)
          .then(res => {
            return res.json();
          }).then(this.setResults);
    },
    newQuery (newvname, vid) {
      this.showLoadModal = true;
      this.query = newvname;
      console.log(vid);
      console.log(newvname);
      fetch(`${this.url_base}${this.query.toUpperCase()}/${vid}`)
          .then(res => {
            return res.json();
          }).then(this.setResults);
    },
    stopnClear () {
      clearTimeout(this.timeOut);
      document.querySelectorAll('.svgline').forEach(e => e.remove());
      document.querySelectorAll('.generatedMarker').forEach(e => e.remove());
      document.querySelectorAll('.leaflet-marker-icon').forEach(e => e.remove());
    },
    getBounds () {
      this.showLoadModal = true;
      let bounds = this.map.getBounds();
      console.log(bounds);
      let nelat = bounds._northEast.lat;
      console.log(nelat);
      let nelng = bounds._northEast.lng;
      console.log(nelng);
      let swlat = bounds._southWest.lat;
      console.log(swlat);
      let swlng = bounds._southWest.lng;
      console.log(swlng);
      this.showModal = true;
      fetch(`${this.local_url}${swlat}/${nelat}/${swlng}/${nelng}`)
          .then(res => {
            return res.json();
          }).then(this.setLocal);
    }
  },
  watch: {
      query: function() {
        if (this.query.length !== 0) {
        fetch(`${this.names_url}${this.query}`)
        .then(res=>{
        return res.json();
        }).then(this.setNames);
        } 
        console.log(this.query.length);
        this.setSearchDisplay();
      },
      vesseldata: function() {
          clearTimeout(this.timeOut);
          var arrayLength = this.vesseldata.length;
          var i = 0;
          this.map.setView([this.vesseldata[i].LAT, this.vesseldata[i].LON], 14);
          let lineplotter = a => {          
            this.timeOut = setTimeout(() => { 
            var j = i + 1;
            //lc1 = latlong combination 1
            var lc1 = [this.vesseldata[i].LAT, this.vesseldata[i].LON];
            //latlong combination 2
            var lc2 = [this.vesseldata[j].LAT, this.vesseldata[j].LON];
            //combine them together for the points that determine our polyline
            var lc3 = [lc1, lc2];
            var timeb = document.getElementsByClassName('time-box');
            var namev = document.getElementsByClassName('namev');
            var dateb = document.getElementsByClassName('date');
            var zulutime = this.vesseldata[i].BaseDateTime;
            var localtime = new Date(zulutime);

            L.marker([this.vesseldata[i].LAT, this.vesseldata[i].LON], {className: 'generatedMarker'})
              .bindPopup(this.vesseldata[i].name)
              .addTo(this.map);
            L.polyline(lc3, {className: 'svgline'}).addTo(this.map); 
            timeb[0].innerHTML = 'Time: ' + localtime.toString().slice(16);
            namev[0].innerHTML = 'Vessel Name: ' + this.query;
            dateb[0].innerHTML = 'Date:' + this.vesseldata[i].BaseDateTime.toString().slice(0,10);
            this.map.panTo(lc1);
            i++;                    
            if (i < arrayLength) {            
                lineplotter();             
              }                        
            }, 100); 
        }
        lineplotter();
      }
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'montserrat', sans-serif;
    background-color:red;
  }
  /*
  #app {
    background-color: blue;
    background-size: cover;
    background-position: bottom;
    transition: 0.4s;
  }
  */
  
  main {
    min-height: 25vh;
    padding: 25px;
    background-color: red;  
  }

  #map {
    width: 98vw;
    max-height: 55vh;
    margin-left: 1vw;
    margin-right: 1vw;
  }

  .search-box {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-box .search-bar {
    display: block;
    width: 100%;
    padding: 15px;
    color: #313131;
    font-size: 20px;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px 20px 0px 0px;
    transition: 0.4s; 
  }

  .search-box .search-bar:focus {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 20px 20px 0px 0px;
  }

  .location-box .location {
    color:white;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-shadow: 1px 3px rgba(0, 0, 0, 0.25);
  }

  .location-box .date {
    color: white;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
  }

  .load-modal {
    position: fixed;
    z-index: 50000;
    height: 100%;
    width: 100%;
    /*top: 33%;
    bottom: 33%;
    left: 33%;
    right: 33%; */
    background-color:white;
    opacity: .75;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchresults {
    background-color: #ffffff;
    max-height: 25vh;
    overflow-y: scroll;
    padding-left: 5px;
    margin-bottom: 10px;
  }

  .dropdown_hidden {
    display: none;
  }

  .dropdown-menu {
    list-style: none;
  }

  .detail-box {
    text-align: center;
    margin-bottom: 10px;
  }

  .detail-box .time-box {
    display: inline-block;
    padding: 0px 25px;
    color: white;
    font-size: 16px;
    font-weight: 900;
    margin: 15px 0px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .wrap {
    margin-bottom: 10px;
  }

  .menu-button {
    background-color:red;
    border-color: white;
    border-width: 1.5px;
    color: white;
    font-size: 15px;
    width: 25%;
    min-width: 99px;
    max-width: 200px;
    height: 40px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .find {
    height:95%;
    width:45%;
    background-image: url("sonar_white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
  }

  .stop {
    height:95%;
    width:45%;
    background-image: url("clear_white_short.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
  }

  .detail-box .namev {
    color: white;
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
  }

  .floatingTop {
    position: absolute;
    z-index: 10000000;
    bottom: 10px;
    width: 40px;
    left: 40%;
    right: 40%;
  }

  .vesselList:hover {
    background-color: rgba(221, 216, 216, 0.945);
  }

.leaflet-marker-icon, .leaflet-marker-shadow {
    display: none;
}

.svg-icon {

  transform: scale(.2, .2);
}

.svgline {
    animation: fadeIn linear 3s;
    -webkit-animation: fadeIn linear 3s;
    -moz-animation: fadeIn linear 3s;
    -o-animation: fadeIn linear 3s;
    -ms-animation: fadeIn linear 3s;
}

  @keyframes fadeIn {
    0% {
      opacity:0;
      stroke: #ff001e;
    }
    25% {
      opacity: 1;
      stroke: #ff2641;
    }
    50% {
        stroke: #fa5569;
    }
    75% {
        stroke: #fc7989;
    }
    100% {
      
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}
@media screen and (max-width: 600px) {
  .menu-button {
    width: 45%;
  }
}

@media screen and (min-width: 535px) {
  #map {
    max-height: 58vh;
  }
}

@media screen and (max-width: 320px) {
  #map {
    max-height: 48vh;
  }
}
</style>
