<template>
    <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              <span class="header-color">Select an item below to review</span> 
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <button v-for="names in ldata" v-bind:key="names.VesselName" @click="vesselSelect" class="data-button" :data-vid="names.MMSI" >
                {{names.VesselName}}
              </button>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              
              <button class="modal-default-button" @click="$emit('close')">
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    ldata: Object
  },
  methods: {
    vesselSelect(e) {
      var vnameText = e.target.innerHTML.toString();
      var vid = e.target.dataset.vid;
      this.$emit('newQuery', vnameText, vid);
      console.log(vnameText);
      this.$emit('close');
    }
  }
};
</script>

<style scoped>

.data-button {
  display: block;
  width: 200px;
  background-color:red;
  border-color: white;
  color: white;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color:red;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.header-color {
  color: white;
}

.modal-body {
  margin: 20px 0;
  max-height: 300px;
  overflow: scroll;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
  background-color:red;
  border-color: white;
  color: white;
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>